export const availablePagesDarkMode: string[] = [
  'BLOG_PAGE',
  'BLOG_SINGLE_PAGE',
  'BLOG_SINGLE_OLD_PAGE',
  'CATALOG_PAGE',
  'CATALOG_MODEL_PAGE',
  'CATALOG_MODEL_COMPLECTATIONS_PAGE',
  'CARS_STOCK_PAGE',
  'CARS_STOCK_COMPARE_PAGE',
  'DETAILED_CAR_PAGE',
  'DEALERS_SINGLE_PAGE',
  'PURCHASE_PAGE',
  'SALE_SERVICE_PAGE',
  'NOT_FOUND',
  // 'SERVICE_SINGLE_PAGE',
  // 'SERVICE_BRAND_PAGE',
  // 'SERVICE_BRAND_SERVICE_PAGE',
];

export const availableTemplatesDarkMode: string[] = [
  '',
  'car-model-landing',
  'car-model-compare-landing',
  'how-to',
  'dealers',
  'insurance',
  'bonus-program',
  'mobile-app',
  'personal-cabinet',
  'home',
  'subscription',
  'eco',
  'special-offer',
  'amp-landing',
  'subdomain-home',
  'vedaem-landing',
  'auto-selection-landing',
  'service-benefits',
  'service-valuation-landing',
  'zeekr-home',
  'finance-landing',
  'tokens-landing',
  // 'b2b-home',
  // 'hisun-home',
  // 'jac-home',
  // 'jetour-home',
  // 'top-level-funnel-landing',
  // 'services',
  // 'help-on-the-road',
  // 'online-shopping',
  // 'service-main',
  // 'value-car',
];
